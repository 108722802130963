.text-container {
  /* height: 100vh;
    width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15rem;

}

@media screen and (max-width: 768px) {
  .text-container{
    margin-top: 5rem;
  }
}

.text-container h1 {
  margin: 2rem;
  /* font-size: 150px; */
  color: rgba(225, 225, 225, 0.01);
  letter-spacing: 4px;
  background-image: url("https://slide-lokalai.s3.amazonaws.com/redgradiant.jpg");
  background-repeat: repeat;
  -webkit-background-clip: text;
  animation: animate 5s ease-in-out infinite;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
}

@keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}
