body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)),
    url(/static/media/bg1.408d0bca.jpg);
  /* background-size: cover; */
  animation: slidein 20s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  height: 200vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;


}

@media screen and (max-width: 768px) {
  padding-top: 150px;
}

@keyframes slidein {
  from {
    background-position: top;
    background-size: 3000px;
  }
  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}


.App-logo {
  height: 30vmin;
  pointer-events: none;
  border-radius: 200px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
  }
}

.App-header {
  margin-top: 3rem;
  padding: 20px;
  background-color: transparent;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #0c6157;
  display: flex;
  flex-direction: column;
}

.button {
  background-color: transparent;
}

.App-link {
  color: #0c6157;
}

.mainContainer {
  padding-top: 5px;
  text-align: center;
  display: block;
  background-color: transparent;
  color: #fff;
  border: none;
}

.title{
  
    /* text-transform: uppercase; */
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 24px;
    background-image: linear-gradient(
      225deg,
      #dc1c13 0%,
      #ea4c46 29%,
      #f07470 45%,
      #f1959b 67%,
      #F6BDC0 85%,
      #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    /* font-size: clamp(2rem, 3vw, 3rem); */

}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
form {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-size: 20px;
  border: none;
}

.predictionContainer {
  padding-top: 0px;
  text-align: center;
  display: block;
  /* border: solid 1px #535e5c; */
  border: none;
}
.predictionResult {
  padding: 5px;
  font-size: xx-large;
  text-align: center;
  /* border: solid 1px #0c6157; */
}
.chartContainer {
  display: inline-block;
  /* display: inline-flexbox; */

  margin: 0 auto;
  /* background-color: #e3d000; */
  /* border: solid 1px #0c6157; */
}

.text-container {
  /* height: 100vh;
    width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15rem;

}

@media screen and (max-width: 768px) {
  .text-container{
    margin-top: 5rem;
  }
}

.text-container h1 {
  margin: 2rem;
  /* font-size: 150px; */
  color: rgba(225, 225, 225, 0.01);
  letter-spacing: 4px;
  background-image: url("https://slide-lokalai.s3.amazonaws.com/redgradiant.jpg");
  background-repeat: repeat;
  -webkit-background-clip: text;
  animation: animate 5s ease-in-out infinite;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
}

@keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

.heroneon {
    position: relative;
    display: inline-block;
    padding: 10px 25px;
    margin: 40px 0;
    color: #DC1C13;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 2px;
    overflow: hidden;
  }
  .heroneon:hover {
    background: #DC1C13;
    color: #050801;
    box-shadow: 0 0 5px #DC1C13, 0 0 25px #DC1C13, 0 0 50px #DC1C13,
      0 0 200px #DC1C13;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  .heroneon:nth-child(1) {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  .heroneon:nth-child(2) {
    -webkit-filter: hue-rotate(110deg);
            filter: hue-rotate(110deg);
  }
  .heroneon span {
    position: absolute;
    display: block;
  }
  .heroneon span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #DC1C13);
    animation: animate1 1s linear infinite;
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .heroneon span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #DC1C13);
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  .heroneon span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #DC1C13);
    animation: animate3 1s linear infinite;
    animation-delay: 0.5s;
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  .heroneon span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #DC1C13);
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
