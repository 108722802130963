.heroneon {
    position: relative;
    display: inline-block;
    padding: 10px 25px;
    margin: 40px 0;
    color: #DC1C13;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 2px;
    overflow: hidden;
  }
  .heroneon:hover {
    background: #DC1C13;
    color: #050801;
    box-shadow: 0 0 5px #DC1C13, 0 0 25px #DC1C13, 0 0 50px #DC1C13,
      0 0 200px #DC1C13;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  .heroneon:nth-child(1) {
    filter: hue-rotate(0deg);
  }
  .heroneon:nth-child(2) {
    filter: hue-rotate(110deg);
  }
  .heroneon span {
    position: absolute;
    display: block;
  }
  .heroneon span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #DC1C13);
    animation: animate1 1s linear infinite;
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .heroneon span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #DC1C13);
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  .heroneon span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #DC1C13);
    animation: animate3 1s linear infinite;
    animation-delay: 0.5s;
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  .heroneon span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #DC1C13);
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }