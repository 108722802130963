.App {
  text-align: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)),
    url("./assets/bg1.jpg");
  /* background-size: cover; */
  -webkit-animation: slidein 20s;
  animation: slidein 20s;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  height: 200vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;


}

@media screen and (max-width: 768px) {
  padding-top: 150px;
}

@-webkit-keyframes slidein {
  from {
    background-position: top;
    background-size: 3000px;
  }
  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}

@keyframes slidein {
  from {
    background-position: top;
    background-size: 3000px;
  }
  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}


.App-logo {
  height: 30vmin;
  pointer-events: none;
  border-radius: 200px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
  }
}

.App-header {
  margin-top: 3rem;
  padding: 20px;
  background-color: transparent;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #0c6157;
  display: flex;
  flex-direction: column;
}

.button {
  background-color: transparent;
}

.App-link {
  color: #0c6157;
}

.mainContainer {
  padding-top: 5px;
  text-align: center;
  display: block;
  background-color: transparent;
  color: #fff;
  border: none;
}

.title{
  
    /* text-transform: uppercase; */
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 24px;
    background-image: linear-gradient(
      225deg,
      #dc1c13 0%,
      #ea4c46 29%,
      #f07470 45%,
      #f1959b 67%,
      #F6BDC0 85%,
      #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    /* font-size: clamp(2rem, 3vw, 3rem); */

}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
form {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-size: 20px;
  border: none;
}

.predictionContainer {
  padding-top: 0px;
  text-align: center;
  display: block;
  /* border: solid 1px #535e5c; */
  border: none;
}
.predictionResult {
  padding: 5px;
  font-size: xx-large;
  text-align: center;
  /* border: solid 1px #0c6157; */
}
.chartContainer {
  display: inline-block;
  /* display: inline-flexbox; */

  margin: 0 auto;
  /* background-color: #e3d000; */
  /* border: solid 1px #0c6157; */
}
